<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="vertical-steps steps-transparent mb-3"
      @on-complete="formSubmitted"
      @on-change="switchTabs"
    >

      <!-- account details tab -->
      <!-- <tab-content
        title="Account Details"
        icon="feather icon-file-text"
	    :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Account Details
              </h5>
              <small class="text-muted">
                Enter your account details...
              </small>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Username"
                label-for="username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  rules="required"
                >
                  <b-form-input
                    id="username"
                    v-model="username"
                    disabled
                    :state="errors.length > 0 ? false:null"
                    placeholder="john-doe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Email"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="emailValue"
                    disabled
                    type="email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john.doe@email.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Password"
                label-for="password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|password"
                >
                  <b-form-input
                    id="password"
                    v-model="PasswordValue"
                    type="password"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Confirm Password"
                label-for="c-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password Confirm"
                  rules="required|confirmed:Password"
                >
                  <b-form-input
                    id="c-password"
                    v-model="passwordCon"
                    type="password"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Re-type Password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content> -->

      <!-- personal details tab -->
      <tab-content
        title="Personal Info"
        icon="feather icon-user"
        :before-change="validationFormInfo"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h2 class="mb-0">
                Personal Information
              </h2>
              <small class="text-muted">Enter your personal infomation...</small>
            </b-col>
            <b-col md="12" class="pb-2">
              <!-- media -->
                <b-media no-body>
                  <b-media-aside>
                    <b-link>
                      <template>
                      <b-img
                        ref="previewEl"
                        rounded
                        :src="(previewImage)?previewImage:require('@/assets/images/avatars/noavatar.png')"
                        height="80"
                      />
                      </template>
                    </b-link>
                    <!--/ avatar  --> 
                  </b-media-aside>

                  <b-media-body class="mt-75 ml-75">
                    <!-- upload button -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="$refs.refInputEl.$el.click()"
                    >
                      Upload
                    </b-button>

                    <b-form-file
                      ref="refInputEl"
                      v-model="profileImageFile"
                      accept=".jpg, .png, .gif"
                      :hidden="true"
                      plain
                      @input="fileImageInput"
                    />
                    <!--/ upload button -->

                    <!--/ reset -->
                    <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
                  </b-media-body>
                </b-media>
                <small v-if="showProfileImageError" class="text-danger"> The profile image field is required</small>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Full Name"
                label-for="full-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="full name"
                  rules="required"
                >
                  <b-form-input
                    id="fullName"
                    v-model="fullName"
                    placeholder="John Doe"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="country"
                rules="required"
              >
                <b-form-group
                  label="Country"
                  label-for="country"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="country"
                    v-model="selectedCountry"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="countryName"
                    label="text"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="language"
                rules="required"
              >
                <b-form-group
                  label="Language"
                  label-for="language"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="language"
                    v-model="selectedLanguage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="languageName"
                    :selectable="option => ! option.value.includes('nothing_selected')"
                    label="text"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

       <!-- profile details tab -->
      <tab-content
        title="Profile Details"
        icon="feather icon-info"
        :before-change="validationProfileDetails"
      >
        <validation-observer
          ref="profileRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h2 class="mb-0">
                Profile Details
              </h2>
              <small class="text-primary">An <span style="font-weight: bold;">Expert</span> is someone who has experinece in a given industry looking to give adive to others also known as <span style="font-weight: bold;">Seekers</span></small> <br />
              <small class="text-primary">A <span style="font-weight: bold;">Seeker</span> is someone who has an idea or a problem that they are looking to get advice from an <span style="font-weight: bold;">Expert</span> </small><br />
              <small class="text-primary">An <span style="font-weight: bold;">Expert or Seeker</span> can be a customer if your looking for validation for an idea or a product. Make sure to include your specializations and interests so people can search those keywords.</small>
                 
            </b-col>
            <!-- <b-col
              cols="12"
              class="mb-2"
            >
              <small class="text-primary">You can manually enter your profile or have GPT generate your profile from your linkedin account (Currenlty takes 24-48 hours)</small> <br />
            </b-col> -->
            <!-- <b-col md="12">
              <b-form-group
                label="Auto Generate Profile"
                label-for="autoProfile"
              >
                <validation-provider
                  #default="{ errors }"
                  name="autoProfile"
                >
                  <b-form-input
                    id="autoProfile"
                    v-model="autoProfile"
                    placeholder="Provide your Linkedin profile (https://www.linkedin.com/in/username/)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="generateProfile"
                    >
                      Generate Profile
                    </b-button>
            </b-col> -->
            <!-- <b-col
              cols="12"
              class="mb-2"
            >
              <small class="text-primary">OR</small> <br />
            </b-col> -->
            <b-col>
              <validation-provider
                #default="{ errors }"
                name="profile type"
                rules="required"
              >
                <b-form-group
                  label="Profile Type"
                  label-for="selectedType"
                  :state="errors.length > 0 ? false:null"
                >
                 <v-select
                    id="selectedType"
                    v-model="selectedType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="categories"
                    :selectable="option => ! option.text.includes('select_value')"
                    label="text"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Tagline"
                label-for="tagline"
              >
                <validation-provider
                  #default="{ errors }"
                  name="tagline"
                  rules="required"
                >
                  <b-form-input
                    id="tagline"
                    v-model="tagline"
                    placeholder="Short creative summary about you (155 words)"
                    :state="errors.length > 0 && errors.length < 156 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Bio"
                label-for="bio"
              >
                <validation-provider
                  #default="{ errors }"
                  name="bio"
                  rules="required"
                >
                  <b-form-textarea
                    id="bio"
                    v-model="bio"
                    rows="4"
                    placeholder="Detailed description about yourself and experience... Sell yourself"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12" v-if="selectedType !== 'Expert' && selectedType.value !== 'Expert'">
              <validation-provider
                #default="{ errors }"
                name="industry"
                rules="required"
              >
                <b-form-group
                  label="Seeking Industry"
                  label-for="industry"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="industry"
                    v-model="selectedIndustry"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="industries"
                    :selectable="option => ! option.text.includes('select_value')"
                    label="text"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="12" v-else>
              <validation-provider
                #default="{ errors }"
                name="industry"
                rules="required"
              >
                <b-form-group
                  label="Industry"
                  label-for="industry"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="industry"
                    v-model="selectedIndustry"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="industries"
                    :selectable="option => ! option.text.includes('select_value')"
                    label="text"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="12">
              <b-form-group 
                label="Professional specializations"
                label-for="Specializations"
              >
                <validation-provider
                  #default="{ errors }"
                  name="specializations"
                >
                  <v-select taggable multiple 
                    id="specializations"
                    placeholder="List your Professional Specializations"
                    :state="null"
                    :options=[]
                    v-model="skills"
                  >
                  <span slot="no-options">Type to add a new specialization</span>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-primary">Professional specializations to match your profile against when poeple are looking for expertise or customers.</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group 
                label="Personal Intrests"
                label-for="Personal Intrests"
              >
                <validation-provider
                  #default="{ errors }"
                  name="personalIntrests"
                >
                  <v-select taggable multiple 
                    id="personalSIntrests"
                    placeholder="List all of your intrests here"
                    :state="null"
                    :options=[]
                    v-model="pSkills"
                  >
                  <span slot="no-options">Type to add a new specialization</span>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-primary">Founders are looking for customers and want feedback on interests like cyclying, gaming, baking, etc to find product market fit.</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group v-if="selectedType !== 'Expert' && selectedType.value !== 'Expert'"
                label="Idea or Problem"
                label-for="idea"
              >
                <validation-provider
                  #default="{ errors }"
                  name="idea or problem"
                  rules="required"
                >
                  <b-form-textarea
                    id="idea"
                    v-model="idea"
                    rows="4"
                    placeholder="Describe your problem or idea that your looking to solve and get expert advice on"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-form-group v-if="selectedType !== 'Seeker' && selectedType.value !== 'Seeker' "
                label="Rate"
                label-for="rate"
              >
                <validation-provider
                  #default="{ errors }"
                  name="rate"
                  rules="required"
                >
                  <b-form-input
                    id="rate"
                    v-model="rate"
                    placeholder="50"
                    type="number"
                    :state="errors.length > 0? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small> <br />
                  <small class="text-primary">Hourly <span style="font-weight: bold;">Rate</span> in dollars that you get paid for when a having a meeting</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

       <!-- notifcation details tab -->
      <tab-content
        title="Notifications"
        icon="feather icon-bell"
      >
        <validation-observer
          ref="notifcationRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h2 class="mb-0">
                Notifications
              </h2>
              <small class="text-muted">Enable Push and Email Notifications</small>
            </b-col>
            <b-col
              cols="12"
              class="mb-2"
            >
              <b-form-checkbox
                id="accountSwitch4"
                :checked="pushNotifications"
                name="check-button"
                switch
                inline
              >
                <span>Incoming Chat Messages</span>
              </b-form-checkbox>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- address -->
      <!-- <tab-content
        title="Address"
        icon="feather icon-map-pin"
        :before-change="validationFormAddress"
      >
        <validation-observer
          ref="addressRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Address
              </h5>
              <small class="text-muted">Enter Your Address.</small>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Address"
                label-for="address"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address"
                  rules="required"
                >
                  <b-form-input
                    id="address"
                    v-model="address"
                    :state="errors.length > 0 ? false:null"
                    placeholder="98 Borough bridge Road, Birmingham"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Landmark"
                label-for="landmark"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Landmark"
                  rules="required"
                >
                  <b-form-input
                    id="landmark"
                    v-model="landMark"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Borough bridge"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Pincode"
                label-for="pincode"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Pincode"
                  rules="required"
                >
                  <b-form-input
                    id="pincode"
                    v-model="pincode"
                    :state="errors.length > 0 ? false:null"
                    type="number"
                    placeholder="658921"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="City"
                label-for="city"
              >
                <validation-provider
                  #default="{ errors }"
                  name="City"
                  rules="required"
                >
                  <b-form-input
                    id="city"
                    v-model="city"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Birmingham"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content> -->

      <!-- social link -->
      <!-- <tab-content
        title="Integrations"
        icon="feather icon-link"
	      :before-change="validationFormSocial"
      >
    	<validation-observer
          ref="socialRules"
          tag="form"
        >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h2 class="mb-0">
              Integrations
            </h2>
            <small class="text-muted">Connect to different Integrations</small>
          </b-col>
          <b-col md="12">
              Comming soon!
          </b-col> -->
          <!-- <b-col md="6">
            <b-form-group
              label="Facebook"
                label-for="facebook"
            >
                <validation-provider
                  #default="{ errors }"
                  name="Facebook"
                  rules="required|url"
                >
              <b-form-input
                    id="facebook"
                    v-model="facebookUrl"
                    :state="errors.length > 0 ? false:null"
                placeholder="https://facebook.com/abc"
              />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="LinkedIn"
                label-for="linked-in"
            >
                <validation-provider
                  #default="{ errors }"
                  name="LinkedIn"
                  rules="required|url"
                >
              <b-form-input
                    id="linked-in"
                    v-model="linkedinUrl"
                    :state="errors.length > 0 ? false:null"
                placeholder="https://linkedin.com/abc"
              />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
          </b-col> -->
        <!-- </b-row>
        </validation-observer>
      </tab-content> -->
    </form-wizard>
    <b-modal id="image-crop-modal" width="500" ok-only ok-variant="secondary" ok-title="Cancel">
      <b-card>
        <b-card-text>
          <VueCropper v-show="profileImageFile" ref="cropper" :aspectRatio="1/1" alt="Source Image"></VueCropper>
        </b-card-text>
        <b-button class="primary" @click="saveImage()">Crop</b-button>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea, 
  BCard,
  BCardText,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody, 
  BLink,
  BButton,
  BFormFile,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import { required, email } from '@validations'
import { validate } from 'vee-validate'
import { getAuth } from "firebase/auth"
import { getFirestore, setDoc, getDoc, doc, updateDoc} from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage, uploadBytes, ref, getDownloadURL } from "firebase/storage";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

const auth = getAuth()
const db = getFirestore()
const storage = getStorage()
const functions = getFunctions()
const user = auth.currentUser

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BImg,
    BMedia,
    BMediaAside,
    BMediaBody, 
    BLink,
    BButton,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCard,
    BCardText,
    vSelect,
    BFormInvalidFeedback,
    VueCropper,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      userData: null,
      selectedCountry: 'United States',
      selectedLanguage: '',
      selectedType: 'Expert',
      selectedIndustry: '',
      fullName: '',
      //username: '',
      //emailValue: '',
      //PasswordValue: '',
      //passwordCon: '',
      //address: '',
      tagline: '',
      bio: '',
      skills: '',
      pSkills: '',
      idea: '',
      industryType: '',
      rate: null,
      previewImage: null,
      profileImageFile: null,
      processing: false,
      mime_type: null,
      pushNotifications: false,
      stripeAccount: null,
      autoProfile: null,

      landMark: '',
      pincode: '',
      twitterUrl: '',
      facebookUrl: '',
      googleUrl: '',
      linkedinUrl: '',
      city: '',
      showProfileImageError: false,
      required,
      email,
      categories: [{text:'Expert', value:'Expert' },{text:'Seeker', value:'Seeker'}], //,{text:'Both', value:'Both'}
      industries: [
        {text:'Accounting', value:'Accounting'},
        {text:'Airlines/Aviation', value:'Airlines/Aviation'},
        {text:'Alternative Dispute Resolution', value:'Alternative Dispute Resolution'},
        {text:'Alternative Medicine', value:'Alternative Medicine'},
        {text:'Animation', value:'Animation'},
        {text:'Apparel & Fashion', value:'Apparel & Fashion'},
        {text:'Architecture & Planning', value:'Architecture & Planning'},
        {text:'Arts & Crafts', value:'Arts & Crafts'},
        {text:'Automotive', value:'Automotive'},
        {text:'Aviation & Aerospace', value:'Aviation & Aerospace'},
        {text:'Banking', value:'Banking'},
        {text:'Biotechnology', value:'Biotechnology'},
        {text:'Broadcast Media', value:'Broadcast Media'},
        {text:'Building Materials', value:'Building Materials'},
        {text:'Business Supplies & Equipment', value:'Business Supplies & Equipment'},
        {text:'Capital Markets', value:'Capital Markets'},
        {text:'Chemicals', value:'Chemicals'},
        {text:'Civil Engineering', value:'Civil Engineering'},
        {text:'Commercial Real Estate', value:'Commercial Real Estate'},
        {text:'Computer & Network Security', value:'Computer & Network Security'},
        {text:'Computer Games', value:'Computer Games'},
        {text:'Computer Hardware', value:'Computer Hardware'},
        {text:'Computer Networking', value:'Computer Networking'},
        {text:'Computer Software', value:'Computer Software'},
        {text:'Construction', value:'Construction'},
        {text:'Consumer Electronics', value:'Consumer Electronics'},
        {text:'Consumer Goods', value:'Consumer Goods'},
        {text:'Consumer Services', value:'Consumer Services'},
        {text:'Cosmetics', value:'Cosmetics'},
        {text:'Dairy', value:'Dairy'},
        {text:'Defense & Space', value:'Defense & Space'},
        {text:'Design', value:'Design'},
        {text:'E-Learning', value:'E-Learning'},
        {text:'Education Management', value:'Education Management'},
        {text:'Electrical & Electronic Manufacturing', value:'Electrical & Electronic Manufacturing'},
        {text:'Entertainment', value:'Entertainment'},
        {text:'Environmental Services', value:'Environmental Services'},
        {text:'Events Services', value:'Events Services'},
        {text:'Executive Office', value:'Executive Office'},
        {text:'Facilities Services', value:'Facilities Services'},
        {text:'Farming', value:'Farming'},
        {text:'Financial Services', value:'Financial Services'},
        {text:'Fine Art', value:'Fine Art'},
        {text:'Fishery', value:'Fishery'},
        {text:'Food & Beverages', value:'Food & Beverages'},
        {text:'Food Production', value:'Food Production'},
        {text:'Fundraising', value:'Fundraising'},
        {text:'Furniture', value:'Furniture'},
        {text:'Gambling & Casinos', value:'Gambling & Casinos'},
        {text:'Glass, Ceramics & Concrete', value:'Glass, Ceramics & Concrete'},
        {text:'Government Administration', value:'Government Administration'},
        {text:'Government Relations', value:'Government Relations'},
        {text:'Graphic Design', value:'Graphic Design'},
        {text:'Health, Wellness & Fitness', value:'Health, Wellness & Fitness'},
        {text:'Higher Education', value:'Higher Education'},
        {text:'Hospital & Health Care', value:'Hospital & Health Care'},
        {text:'Hospitality', value:'Hospitality'},
        {text:'Human Resources', value:'Human Resources'},
        {text:'Import & Export', value:'Import & Export'},
        {text:'Individual & Family Services', value:'Individual & Family Services'},
        {text:'Industrial Automation', value:'Industrial Automation'},
        {text:'Information Services', value:'Information Services'},
        {text:'Information Technology & Services', value:'Information Technology & Services'},
        {text:'Insurance', value:'Insurance'},
        {text:'International Affairs', value:'International Affairs'},
        {text:'International Trade and Development', value:'International Trade and Development '},
        {text:'Internet', value:'Internet'},
        {text:'Investment Banking', value:'Investment Banking'},
        {text:'Judiciary', value:'Judiciary'},
        {text:'Law Enforcement', value:'Law Enforcement'},
        {text:'Law Practice', value:'Law Practice'},
        {text:'Legal Services', value:'Legal Services'},
        {text:'Legislative Office', value:'Legislative Office'},
        {text:'Leisure, Travel & Tourism', value:'Leisure, Travel & Tourism'},
        {text:'Libraries', value:'Libraries'},
        {text:'Logistics & Supply Chain', value:'Logistics & Supply Chain'},
        {text:'Luxury Goods & Jewelry', value:'Luxury Goods & Jewelry'},
        {text:'Machinery', value:'Machinery'},
        {text:'Management Consulting', value:'Management Consulting'},
        {text:'Maritime', value:'Maritime'},
        {text:'Market Research', value:'Market Research'},
        {text:'Marketing & Advertising', value:'Marketing & Advertising'},
        {text:'Mechanical or Industrial Engineering', value:'Mechanical or Industrial Engineering'},
        {text:'Media Production', value:'Media Production'},
        {text:'Medical Device', value:'Medical Device'},
        {text:'Medical Practice', value:'Medical Practice'},
        {text:'Mental Health Care', value:'Mental Health Care'},
        {text:'Military', value:'Military'},
        {text:'Mining & Metals', value:'Mining & Metals'},
        {text:'Mobile Games', value:'Mobile Games'},
        {text:'Motion Pictures & FIlm', value:'Motion Pictures & FIlm'},
        {text:'Museums & Institutions', value:'Museums & Institutions'},
        {text:'Music', value:'Music'},
        {text:'Nanotechnology', value:'Nanotechnology'},
        {text:'Newspapers', value:'Newspapers'},
        {text:'Non-profit Organization Management', value:'Non-profit Organization Management'},
        {text:'Oil & Energy', value:'Oil & Energy'},
        {text:'Online Media', value:'Online Media'},
        {text:'Outsourcing/Offshoring', value:'Outsourcing/Offshoring'},
        {text:'Package/Freight Delivery', value:'Package/Freight Delivery'},
        {text:'Packaging & Containers', value:'Packaging & Containers'},
        {text:'Paper & Forest Products', value:'Paper & Forest Products'},
        {text:'Performing Arts', value:'Performing Arts'},
        {text:'Pharmaceuticals', value:'Pharmaceuticals'},
        {text:'Philanthropy', value:'Philanthropy'},
        {text:'Photography', value:'Photography'},
        {text:'Plastics', value:'Plastics'},
        {text:'Political Organization', value:'Political Organization'},
        {text:'Primary/Secondary Education', value:'Primary/Secondary Education'},
        {text:'Printing', value:'Printing'},
        {text:'Professional Training & Coaching', value:'Professional Training & Coaching'},
        {text:'Program Development', value:'Program Development'},
        {text:'Public Policy', value:'Public Policy'},
        {text:'Public Relations & Communications', value:'Public Relations & Communications'},
        {text:'Public Safety', value:'Public Safety'},
        {text:'Publishing', value:'Publishing'},
        {text:'Railroad Manufacture', value:'Railroad Manufacture'},
        {text:'Ranching', value:'Ranching'},
        {text:'Real Estate', value:'Real Estate'},
        {text:'Recreational Facilities & Services', value:'Recreational Facilities & Services'},
        {text:'Religious Institution', value:'Religious Institution'},
        {text:'Renewables & Environment', value:'Renewables & Environment'},
        {text:'Research', value:'Research'},
        {text:'Restaurants', value:'Restaurants'},
        {text:'Retail', value:'Retail'},
        {text:'Security & Investigations', value:'Security & Investigations'},
        {text:'Semiconductors', value:'Semiconductors'},
        {text:'Shipbuilding', value:'Shipbuilding'},
        {text:'Sporting Goods', value:'Sporting Goods'},
        {text:'Sports', value:'Sports'},
        {text:'Staffing & Recruiting', value:'Staffing & Recruiting'},
        {text:'Supermarkets', value:'Supermarkets'},
        {text:'Telecommunications', value:'Telecommunications'},
        {text:'Textiles', value:'Textiles'},
        {text:'Think Tanks', value:'Think Tanks'},
        {text:'Tobacco', value:'Tobacco'},
        {text:'Translation & Localization', value:'Translation & Localization'},
        {text:'Transportation/Trucking/Railroad', value:'Transportation/Trucking/Railroad'},
        {text:'Utilities', value:'Utilities'},
        {text:'Venture Capital & Private Equity', value:'Venture Capital & Private Equity'},
        {text:'Veterinary', value:'Veterinary'},
        {text:'Warehousing', value:'Warehousing'},
        {text:'Wine & Spirits', value:'Wine & Spirits'},
        {text:'Wireless', value:'Wireless'},
        {text:'Writing & Editing', value:'Writing & Editing'},
      ],
      countryName: [
        {text: 'Afghanistan', code: 'AF'}, 
        {text: 'Åland Islands', code: 'AX'}, 
        {text: 'Albania', code: 'AL'}, 
        {text: 'Algeria', code: 'DZ'}, 
        {text: 'American Samoa', code: 'AS'}, 
        {text: 'AndorrA', code: 'AD'}, 
        {text: 'Angola', code: 'AO'}, 
        {text: 'Anguilla', code: 'AI'}, 
        {text: 'Antarctica', code: 'AQ'}, 
        {text: 'Antigua and Barbuda', code: 'AG'}, 
        {text: 'Argentina', code: 'AR'}, 
        {text: 'Armenia', code: 'AM'}, 
        {text: 'Aruba', code: 'AW'}, 
        {text: 'Australia', code: 'AU'}, 
        {text: 'Austria', code: 'AT'}, 
        {text: 'Azerbaijan', code: 'AZ'}, 
        {text: 'Bahamas', code: 'BS'}, 
        {text: 'Bahrain', code: 'BH'}, 
        {text: 'Bangladesh', code: 'BD'}, 
        {text: 'Barbados', code: 'BB'}, 
        {text: 'Belarus', code: 'BY'}, 
        {text: 'Belgium', code: 'BE'}, 
        {text: 'Belize', code: 'BZ'}, 
        {text: 'Benin', code: 'BJ'}, 
        {text: 'Bermuda', code: 'BM'}, 
        {text: 'Bhutan', code: 'BT'}, 
        {text: 'Bolivia', code: 'BO'}, 
        {text: 'Bosnia and Herzegovina', code: 'BA'}, 
        {text: 'Botswana', code: 'BW'}, 
        {text: 'Bouvet Island', code: 'BV'}, 
        {text: 'Brazil', code: 'BR'}, 
        {text: 'British Indian Ocean Territory', code: 'IO'}, 
        {text: 'Brunei Darussalam', code: 'BN'}, 
        {text: 'Bulgaria', code: 'BG'}, 
        {text: 'Burkina Faso', code: 'BF'}, 
        {text: 'Burundi', code: 'BI'}, 
        {text: 'Cambodia', code: 'KH'}, 
        {text: 'Cameroon', code: 'CM'}, 
        {text: 'Canada', code: 'CA'}, 
        {text: 'Cape Verde', code: 'CV'}, 
        {text: 'Cayman Islands', code: 'KY'}, 
        {text: 'Central African Republic', code: 'CF'}, 
        {text: 'Chad', code: 'TD'}, 
        {text: 'Chile', code: 'CL'}, 
        {text: 'China', code: 'CN'}, 
        {text: 'Christmas Island', code: 'CX'}, 
        {text: 'Cocos (Keeling) Islands', code: 'CC'}, 
        {text: 'Colombia', code: 'CO'}, 
        {text: 'Comoros', code: 'KM'}, 
        {text: 'Congo', code: 'CG'}, 
        {text: 'Congo, The Democratic Republic of the', code: 'CD'}, 
        {text: 'Cook Islands', code: 'CK'}, 
        {text: 'Costa Rica', code: 'CR'}, 
        {text: 'Cote D\'Ivoire', code: 'CI'}, 
        {text: 'Croatia', code: 'HR'}, 
        {text: 'Cuba', code: 'CU'}, 
        {text: 'Cyprus', code: 'CY'}, 
        {text: 'Czech Republic', code: 'CZ'}, 
        {text: 'Denmark', code: 'DK'}, 
        {text: 'Djibouti', code: 'DJ'}, 
        {text: 'Dominica', code: 'DM'}, 
        {text: 'Dominican Republic', code: 'DO'}, 
        {text: 'Ecuador', code: 'EC'}, 
        {text: 'Egypt', code: 'EG'}, 
        {text: 'El Salvador', code: 'SV'}, 
        {text: 'Equatorial Guinea', code: 'GQ'}, 
        {text: 'Eritrea', code: 'ER'}, 
        {text: 'Estonia', code: 'EE'}, 
        {text: 'Ethiopia', code: 'ET'}, 
        {text: 'Falkland Islands (Malvinas)', code: 'FK'}, 
        {text: 'Faroe Islands', code: 'FO'}, 
        {text: 'Fiji', code: 'FJ'}, 
        {text: 'Finland', code: 'FI'}, 
        {text: 'France', code: 'FR'}, 
        {text: 'French Guiana', code: 'GF'}, 
        {text: 'French Polynesia', code: 'PF'}, 
        {text: 'French Southern Territories', code: 'TF'}, 
        {text: 'Gabon', code: 'GA'}, 
        {text: 'Gambia', code: 'GM'}, 
        {text: 'Georgia', code: 'GE'}, 
        {text: 'Germany', code: 'DE'}, 
        {text: 'Ghana', code: 'GH'}, 
        {text: 'Gibraltar', code: 'GI'}, 
        {text: 'Greece', code: 'GR'}, 
        {text: 'Greenland', code: 'GL'}, 
        {text: 'Grenada', code: 'GD'}, 
        {text: 'Guadeloupe', code: 'GP'}, 
        {text: 'Guam', code: 'GU'}, 
        {text: 'Guatemala', code: 'GT'}, 
        {text: 'Guernsey', code: 'GG'}, 
        {text: 'Guinea', code: 'GN'}, 
        {text: 'Guinea-Bissau', code: 'GW'}, 
        {text: 'Guyana', code: 'GY'}, 
        {text: 'Haiti', code: 'HT'}, 
        {text: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
        {text: 'Holy See (Vatican City State)', code: 'VA'}, 
        {text: 'Honduras', code: 'HN'}, 
        {text: 'Hong Kong', code: 'HK'}, 
        {text: 'Hungary', code: 'HU'}, 
        {text: 'Iceland', code: 'IS'}, 
        {text: 'India', code: 'IN'}, 
        {text: 'Indonesia', code: 'ID'}, 
        {text: 'Iran, Islamic Republic Of', code: 'IR'}, 
        {text: 'Iraq', code: 'IQ'}, 
        {text: 'Ireland', code: 'IE'}, 
        {text: 'Isle of Man', code: 'IM'}, 
        {text: 'Israel', code: 'IL'}, 
        {text: 'Italy', code: 'IT'}, 
        {text: 'Jamaica', code: 'JM'}, 
        {text: 'Japan', code: 'JP'}, 
        {text: 'Jersey', code: 'JE'}, 
        {text: 'Jordan', code: 'JO'}, 
        {text: 'Kazakhstan', code: 'KZ'}, 
        {text: 'Kenya', code: 'KE'}, 
        {text: 'Kiribati', code: 'KI'}, 
        {text: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
        {text: 'Korea, Republic of', code: 'KR'}, 
        {text: 'Kuwait', code: 'KW'}, 
        {text: 'Kyrgyzstan', code: 'KG'}, 
        {text: 'Lao People\'S Democratic Republic', code: 'LA'}, 
        {text: 'Latvia', code: 'LV'}, 
        {text: 'Lebanon', code: 'LB'}, 
        {text: 'Lesotho', code: 'LS'}, 
        {text: 'Liberia', code: 'LR'}, 
        {text: 'Libyan Arab Jamahiriya', code: 'LY'}, 
        {text: 'Liechtenstein', code: 'LI'}, 
        {text: 'Lithuania', code: 'LT'}, 
        {text: 'Luxembourg', code: 'LU'}, 
        {text: 'Macao', code: 'MO'}, 
        {text: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
        {text: 'Madagascar', code: 'MG'}, 
        {text: 'Malawi', code: 'MW'}, 
        {text: 'Malaysia', code: 'MY'}, 
        {text: 'Maldives', code: 'MV'}, 
        {text: 'Mali', code: 'ML'}, 
        {text: 'Malta', code: 'MT'}, 
        {text: 'Marshall Islands', code: 'MH'}, 
        {text: 'Martinique', code: 'MQ'}, 
        {text: 'Mauritania', code: 'MR'}, 
        {text: 'Mauritius', code: 'MU'}, 
        {text: 'Mayotte', code: 'YT'}, 
        {text: 'Mexico', code: 'MX'}, 
        {text: 'Micronesia, Federated States of', code: 'FM'}, 
        {text: 'Moldova, Republic of', code: 'MD'}, 
        {text: 'Monaco', code: 'MC'}, 
        {text: 'Mongolia', code: 'MN'}, 
        {text: 'Montserrat', code: 'MS'}, 
        {text: 'Morocco', code: 'MA'}, 
        {text: 'Mozambique', code: 'MZ'}, 
        {text: 'Myanmar', code: 'MM'}, 
        {text: 'Namibia', code: 'NA'}, 
        {text: 'Nauru', code: 'NR'}, 
        {text: 'Nepal', code: 'NP'}, 
        {text: 'Netherlands', code: 'NL'}, 
        {text: 'Netherlands Antilles', code: 'AN'}, 
        {text: 'New Caledonia', code: 'NC'}, 
        {text: 'New Zealand', code: 'NZ'}, 
        {text: 'Nicaragua', code: 'NI'}, 
        {text: 'Niger', code: 'NE'}, 
        {text: 'Nigeria', code: 'NG'}, 
        {text: 'Niue', code: 'NU'}, 
        {text: 'Norfolk Island', code: 'NF'}, 
        {text: 'Northern Mariana Islands', code: 'MP'}, 
        {text: 'Norway', code: 'NO'}, 
        {text: 'Oman', code: 'OM'}, 
        {text: 'Pakistan', code: 'PK'}, 
        {text: 'Palau', code: 'PW'}, 
        {text: 'Palestinian Territory, Occupied', code: 'PS'}, 
        {text: 'Panama', code: 'PA'}, 
        {text: 'Papua New Guinea', code: 'PG'}, 
        {text: 'Paraguay', code: 'PY'}, 
        {text: 'Peru', code: 'PE'}, 
        {text: 'Philippines', code: 'PH'}, 
        {text: 'Pitcairn', code: 'PN'}, 
        {text: 'Poland', code: 'PL'}, 
        {text: 'Portugal', code: 'PT'}, 
        {text: 'Puerto Rico', code: 'PR'}, 
        {text: 'Qatar', code: 'QA'}, 
        {text: 'Reunion', code: 'RE'}, 
        {text: 'Romania', code: 'RO'}, 
        {text: 'Russian Federation', code: 'RU'}, 
        {text: 'RWANDA', code: 'RW'}, 
        {text: 'Saint Helena', code: 'SH'}, 
        {text: 'Saint Kitts and Nevis', code: 'KN'}, 
        {text: 'Saint Lucia', code: 'LC'}, 
        {text: 'Saint Pierre and Miquelon', code: 'PM'}, 
        {text: 'Saint Vincent and the Grenadines', code: 'VC'}, 
        {text: 'Samoa', code: 'WS'}, 
        {text: 'San Marino', code: 'SM'}, 
        {text: 'Sao Tome and Principe', code: 'ST'}, 
        {text: 'Saudi Arabia', code: 'SA'}, 
        {text: 'Senegal', code: 'SN'}, 
        {text: 'Serbia and Montenegro', code: 'CS'}, 
        {text: 'Seychelles', code: 'SC'}, 
        {text: 'Sierra Leone', code: 'SL'}, 
        {text: 'Singapore', code: 'SG'}, 
        {text: 'Slovakia', code: 'SK'}, 
        {text: 'Slovenia', code: 'SI'}, 
        {text: 'Solomon Islands', code: 'SB'}, 
        {text: 'Somalia', code: 'SO'}, 
        {text: 'South Africa', code: 'ZA'}, 
        {text: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
        {text: 'Spain', code: 'ES'}, 
        {text: 'Sri Lanka', code: 'LK'}, 
        {text: 'Sudan', code: 'SD'}, 
        {text: 'Suritext', code: 'SR'}, 
        {text: 'Svalbard and Jan Mayen', code: 'SJ'}, 
        {text: 'Swaziland', code: 'SZ'}, 
        {text: 'Sweden', code: 'SE'}, 
        {text: 'Switzerland', code: 'CH'}, 
        {text: 'Syrian Arab Republic', code: 'SY'}, 
        {text: 'Taiwan, Province of China', code: 'TW'}, 
        {text: 'Tajikistan', code: 'TJ'}, 
        {text: 'Tanzania, United Republic of', code: 'TZ'}, 
        {text: 'Thailand', code: 'TH'}, 
        {text: 'Timor-Leste', code: 'TL'}, 
        {text: 'Togo', code: 'TG'}, 
        {text: 'Tokelau', code: 'TK'}, 
        {text: 'Tonga', code: 'TO'}, 
        {text: 'Trinidad and Tobago', code: 'TT'}, 
        {text: 'Tunisia', code: 'TN'}, 
        {text: 'Turkey', code: 'TR'}, 
        {text: 'Turkmenistan', code: 'TM'}, 
        {text: 'Turks and Caicos Islands', code: 'TC'}, 
        {text: 'Tuvalu', code: 'TV'}, 
        {text: 'Uganda', code: 'UG'}, 
        {text: 'Ukraine', code: 'UA'}, 
        {text: 'United Arab Emirates', code: 'AE'}, 
        {text: 'United Kingdom', code: 'GB'}, 
        {text: 'United States', code: 'US'}, 
        {text: 'United States Minor Outlying Islands', code: 'UM'}, 
        {text: 'Uruguay', code: 'UY'}, 
        {text: 'Uzbekistan', code: 'UZ'}, 
        {text: 'Vanuatu', code: 'VU'}, 
        {text: 'Venezuela', code: 'VE'}, 
        {text: 'Viet Nam', code: 'VN'}, 
        {text: 'Virgin Islands, British', code: 'VG'}, 
        {text: 'Virgin Islands, U.S.', code: 'VI'}, 
        {text: 'Wallis and Futuna', code: 'WF'}, 
        {text: 'Western Sahara', code: 'EH'}, 
        {text: 'Yemen', code: 'YE'}, 
        {text: 'Zambia', code: 'ZM'}, 
        {text: 'Zimbabwe', code: 'ZW'} 
      ],
      languageName: [
        { value: 'nothing_selected', text: 'Nothing Selected' },
        { value: 'English', text: 'English' },
        { value: 'Chinese', text: 'Mandarin Chinese' },
        { value: 'Hindi', text: 'Hindi' },
        { value: 'Spanish', text: 'Spanish' },
        { value: 'Arabic', text: 'Arabic' },
        { value: 'Malay', text: 'Malay' },
        { value: 'Russian', text: 'Russian' },
      ],
    }
  },
  directives: {
    Ripple,
  },
  created: async function() {
    
    this.userData = JSON.parse(localStorage.getItem('userData'))
    const profileRes = await getDoc(doc(db, "profiles",user.displayName))
    
    // Populate the profile
    if(profileRes.exists()){
        const profile = profileRes.data()

        this.fullName = profile.fullName
        this.selectedCountry = (profile.country)?profile.country:'United States'
        this.selectedLanguage = (profile.language)?profile.language:'English'
        this.tagline = (profile.tagline)?profile.tagline:''
        this.selectedIndustry = (profile.industry)?profile.industry:''
        this.selectedType = (profile.type)?profile.type:'Expert'
        this.previewImage = (profile.image)?profile.image:null
        //this.profileImageFile = (profile.image)?this.$refs['previewEl']:null
        this.pushNotifications = (profile.pushTokens && profile.pushTokens > 0)?true:false
        this.stripeAccount = (profile.stripeAccount)?profile.stripeAccount:null
        this.bio = (profile.bio)?profile.bio:''
        this.skills = (profile.skills)?profile.skills:''
        this.pSkills = (profile.pSkills)?profile.pSkills:''
        this.idea = (profile.idea)?profile.idea:''
        this.rate = (profile.rate)?profile.rate:''
    }
  },
  methods: {
    async generateProfile(){
      console.log('generateProfile')

      if(!this.autoProfile || this.autoProfile.lenght < 10){
        this.$bvModal.msgBoxConfirm('Please specify a valid linkedin profile URL', {
            title: 'Generate Profile',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'OK',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: true,
            centered: true,
          })
          return;
      }

      if(user.displayName){
        const startAutoGenerateProfile = httpsCallable(functions, 'startAutoGenerateProfile')
        const generate = await startAutoGenerateProfile({linkedin:this.autoProfile, currentUser:user.displayName })
        // Check if we recived a Payment Intent
        if(generate && generate.data.success){
          this.$bvModal.msgBoxConfirm('ChatGPT Profile Generation Started... Please allow 24-48 hours.', {
            title: 'Generate Profile',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Ok',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: true,
            centered: true,
          })
        }
      }
    },
    fileImageInput(file) {
      if (file && file.name) {
        this.processing = true
        this.$bvModal.show('image-crop-modal')

        // Read file
        const fr = new FileReader()
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          // this is to load image on the UI
          this.mime_type = file.type
          this.previewImage = fr.result
          this.$refs.cropper.replace(this.previewImage)
        })
      }
    },
    saveImage(){
      try {
        const cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
        this.$refs.cropper.getCroppedCanvas().toBlob(async (blob) => {
          // Upload file
          const metadata = { contentType: this.mime_type }
          const storageRef = ref(storage, 'profiles/' + user.displayName)
          const uploadTask = await uploadBytes(storageRef, blob, metadata)

          // Get the download URL
          const newImageUrl = await getDownloadURL(storageRef)
          this.userData.image = (newImageUrl)?newImageUrl:this.userData.image

          // Close Dialog
          this.$bvModal.hide('image-crop-modal')
        }, this.mime_type)

      } catch (e) {
        console.error(e);
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Profile Image Upload Error `,
            icon: 'ImageIcon',
            variant: 'danger',
            text: 'There was an issue uploading your profile image please try again.',
          },
        })
      } finally {
        this.processing = false;
      }
    },
    async formSubmitted() {

      // Validate form for to make sure data is correct
      const validateForm = await this.$refs.profileRules.validate()
      if (!validateForm) return

      this.userData.country = (this.userData.country)?this.userData.country:'United States'
      this.userData.language = (this.userData.language)?this.userData.language:'English'

      // Edit the profile of the user
      this.userData.country = (this.selectedCountry.text)?this.selectedCountry.text:this.userData.country
      this.userData.language = (this.selectedLanguage.value)?this.selectedLanguage.value:this.userData.language
      this.userData.industry = (this.selectedIndustry.value)?this.selectedIndustry.value:this.userData.industry
      this.userData.type = (this.selectedType.value)?this.selectedType.value:'Expert'
      this.userData.fullName = this.fullName
      this.userData.tagline = (this.tagline)?this.tagline:''
      this.userData.bio = (this.bio)?this.bio:''
      this.userData.skills = (this.skills)?this.skills:''
      this.userData.pSkills = (this.pSkills)?this.pSkills:''
      this.userData.idea = (this.idea)?this.idea:''
      this.userData.rate = (this.rate)?this.rate:''
      this.userData.profileComplete = true;
      
      // Update the profile  
      await setDoc(doc(db, "profiles", user.displayName ), this.userData, { merge: true })

      // Update the local storage
      localStorage.setItem('userData', JSON.stringify(this.userData))

      // Do we need update availability
      if(this.userData.availability){
        // Go to search
        this.$router.replace('/app/search').then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Profile Updated',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
      }else{
        this.$router.replace('/app/availability').then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Profile Updated',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
      }
    },
    notifcationSupported(){
      return 'Notification' in window &&
      'serviceWorker' in navigator &&
      'PushManager' in window
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            // Validate form for to make sure profile image is set
            validate(this.previewImage, 'required').then(result => {
              if (result.valid) {
                this.showProfileImageError = false
                resolve(true)
              }else{
                this.showProfileImageError = true
                reject()
              }
            }) 
          } else {
            reject()
          }
        })
      })
    },
    validationProfileDetails() {
      return new Promise((resolve, reject) => {
        this.$refs.profileRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    async requestPushPermission() {
      console.log('Requesting permission...');
      if (isSupported() && this.notifcationSupported()) {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            console.log('Notification permission granted.')
              this.requestPushToken()
          } else {
            console.log('Unable to get permission to notify.')
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Notifications Failed`,
                icon: 'user-x',
                variant: 'danger',
                text: 'Unable to get permission for Notifications.',
              },
            })
          }
        })
      }
    },
    showConfirmationDialog(){
      // Show permission request UI
      this.$bvModal.msgBoxConfirm('Allow Hustleforge to send notifications for chat messages', {
        title: 'Push Notifications',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: true,
        centered: true,
      }).then(value => {
        if(value) this.requestPushPermission()
        else{
          this.pushNotifications = false
          localStorage.setItem('userData', JSON.stringify(this.userData))
        }
      })
    },
    requestPushToken(){
      // Get registration token. Initially this makes a network call, once retrieved
      // subsequent calls to getToken will return from cache.
      if(!isSupported()) return
      const messaging = getMessaging()
      getToken(messaging, { vapidKey: 'BJluftTUTm76uZqpL8BhlG0I_d25Hos_qxZhciubU-3MAWbcjrbnd-pRgf3WFB_NQIO7B5YLbgmE1SxfNUYHeck' }).then((currentToken) => {
        console.log(currentToken)
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // ...
          // TODO review this?? 
          this.pushNotifications = true
          if(this.userData.pushToken !== currentToken){
            console.log("update User Token")
            this.userData.pushToken = currentToken

            // Update the profile  
            updateDoc(doc(db, "profiles", user.displayName ), { pushTokens: arrayUnion(currentToken)})
          
            // Update the local storage
            localStorage.setItem('userData', JSON.stringify(this.userData))
          }

        } else {
          console.log('No registration token available. Request permission to generate one.')
          this.showConfirmationDialog()
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err)
        this.showConfirmationDialog()
      })
    },
    showStripeConfirmationDialog(){
      // Show permission request UI
      this.$bvModal.msgBoxConfirm('Stripe Connect account is reqired to process payments from users of Hustleforge', {
        title: 'Stripe Payments',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: true,
        centered: true,
      }).then(value => {
        if(value) this.connectToStripe()
      })
    },
    async connectToStripe(){
      console.log('connectToStripe')
      const stripeConnect = httpsCallable(functions, 'stripeAccountURL')
      const stripeResponse = await stripeConnect({ email: auth.currentUser.email })
      console.log(stripeResponse)
      if(stripeResponse && stripeResponse.data.url) window.open(stripeResponse.data.url, '_blank')
    },
    switchTabs(currentTab){
      //console.log(currentTab)
      if(auth.currentUser && currentTab === 1) this.requestPushToken()
      //else if(auth.currentUser && currentTab === 2 && this.selectedType !== 'Seeker' && this.stripeAccount === null) this.showStripeConfirmationDialog()
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>



